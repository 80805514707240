.contactCard_c1bn1b88.MuiGrid-root{background-color:#F8F8F8;padding:24px;border-radius:10px;}
.backButton_bhntqqp{min-width:212px !important;}.backButton_bhntqqp p{font-weight:700;}
.selectedTimeslot_s8vhuxw{color:#524658;background-color:#F8F8F8;border-radius:20px;padding:16px;}.selectedTimeslot_s8vhuxw .MuiTypography-root{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}.selectedTimeslot_s8vhuxw .MuiTypography-root span:last-child{font-weight:400;}.selectedTimeslot_s8vhuxw b{margin-right:8px;}@media (min-width:960px){.selectedTimeslot_s8vhuxw{text-align:left;}.selectedTimeslot_s8vhuxw .MuiTypography-root{display:block;}}
.buttonContainer_brx08kr{width:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;gap:8px;margin-bottom:24px;margin-top:24px;}@media (max-width:959.95px){.buttonContainer_brx08kr{-webkit-flex-direction:column-reverse;-ms-flex-direction:column-reverse;flex-direction:column-reverse;}}@media (min-width:600px){.buttonContainer_brx08kr{-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;}}
.skipMessage_s11m062x a{color:#17AB33;}
.checkboxesContainer_c12ic0le .MuiFormControlLabel-root > .MuiTypography-body1{font-size:1.125rem;color:#2E0F4F;}.checkboxesContainer_c12ic0le.MuiFormGroup-root{-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;}.checkboxesContainer_c12ic0le .MuiRadio-root,.checkboxesContainer_c12ic0le .MuiRadio-colorSecondary.Mui-checked{color:#2E0F4F;}.checkboxesContainer_c12ic0le .MuiTypography-body2{font-weight:400;}
.loading_lu4c36n{margin-left:24px;vertical-align:middle;}
.icons_i1jjfqsk .MuiGrid-spacing-xs-8{width:100%;margin:0px;}@media (max-width:959.95px){.icons_i1jjfqsk .MuiGrid-spacing-xs-8 > .MuiGrid-item:not(:last-child){padding-bottom:16px;}}.icons_i1jjfqsk .MuiGrid-spacing-xs-8 > .MuiGrid-item{padding:0px;}@media (max-width:599.95px){.icons_i1jjfqsk .MuiGrid-spacing-xs-8 > .MuiGrid-item{-webkit-align-items:start;-webkit-box-align:start;-ms-flex-align:start;align-items:start;text-align:start;}}
.learnMore_lzlonei{font-size:18px;}
.button_b1rzs9nd{font-weight:700 !important;}
