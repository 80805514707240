@media (min-width:960px){.wrapper_wbh6gdx{overflow-x:auto;width:100%;}}
.root_r158s9ro{margin:0 auto;padding-left:32px;padding-right:32px;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;}@media (max-width:959.95px){.root_r158s9ro{padding-left:5vw;padding-right:5vw;}}.root_r158s9ro img{object-fit:contain;width:100%;}.root_r158s9ro .MuiTableCell-body{background-color:#fff !important;}.root_r158s9ro::-webkit-scrollbar{color:white;padding-bottom:0px;}.root_r158s9ro tr:nth-of-type(odd) th:first-child,.root_r158s9ro .MuiTableRow-root:not(:last-child,:first-child){background-color:#fff !important;}.root_r158s9ro .MuiTableRow-root{margin:0px;}@media (min-width:960px){.root_r158s9ro .MuiTableRow-root th{text-align:center;}}@media (max-width:599.95px){.root_r158s9ro .MuiTableRow-root th .MuiTypography-root p,.root_r158s9ro .MuiTableRow-root td{font-size:0.75rem;}}.root_r158s9ro .MuiTableRow-root:nth-of-type(even) thead,.root_r158s9ro .MuiTableRow-root:nth-of-type(even) .MuiTableCell-root{background-color:#F8F8F8 !important;}.root_r158s9ro .MuiTableCell-root{overflow:hidden;padding:8px 16px !important;box-sizing:border-box;text-align:center;border:none;border-width:0;font-weight:400;}@media (min-width:1280px){.root_r158s9ro .MuiTableCell-root{padding:0 8px;}}.root_r158s9ro .MuiTableCell-root p{line-height:1.2;margin:0;}.root_r158s9ro .MuiTableCell-root:first-child p{text-align:left;}.root_r158s9ro .MuiTableCell-root:not(:last-child){border-right:solid 1px rgba(82,70,88,0.16);}
.table_tpsoz3j{margin:0 auto;border-radius:4px;border:1px solid rgba(0,0,0,0.12);}.table_tpsoz3j.MuiTable-root{border-collapse:separate;overflow:hidden;width:auto;}
.tableCell_ti40iwf{width:180px;min-width:180px;font-size:0.75rem !important;display:table-cell;}.tableCell_ti40iwf:first-child{width:200px;min-width:200px;}@media (max-width:959.95px){.tableCell_ti40iwf:first-child{min-width:30vw;width:30vw;}}.tableCell_ti40iwf .MuiTypography-root{font-weight:400;color:rgba(0,0,0,0.87);}@media (max-width:959.95px){.tableCell_ti40iwf{min-width:30vw;width:30vw;}}
.tableCellCompany_t19x8dhn > .MuiBox-root{height:40px;}
.checkBox_c6p9yr0{margin-bottom:24px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;width:100vw;}.checkBox_c6p9yr0 .MuiCheckbox-root{color:#2E0F4F;padding:0;margin-right:8px;}
.checkBoxLabel_cnh886w .MuiTypography-root{font-weight:500;color:#2E0F4F;}
.title_t8guef0.MuiTypography-h3{text-align:center;padding-top:32px;padding-bottom:24px;color:#2E0F4F;}@media (min-width:960px){.title_t8guef0.MuiTypography-h3{padding-top:0;padding-bottom:40px;font-size:2.188rem;}}.title_t8guef0.MuiTypography-subtitle2{padding-top:16px;padding-bottom:32px;font-weight:400;margin-left:64px;color:#2E0F4F;}@media (min-width:960px){.title_t8guef0.MuiTypography-subtitle2{width:90%;text-align:right;}}
.emptyHeaderCell_e1f2kcw2{height:48px;}
.logo_l8npzyn{max-width:90%;}@media (min-width:960px){.logo_l8npzyn{max-width:100%;}}
.doneIncon_d7m9m0q svg{color:#4caf50;}
.closeIcon_c12eku6f svg{color:#FF3D3D;}
@media (max-width:599.95px){.providerName_p1isrv50{max-width:73px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;font-size:0.9375rem !important;}}@media (min-width:960px){.providerName_p1isrv50{color:#524658 !important;font-weight:700 !important;font-size:1.125rem !important;}}
.primaryColourCell_pexu3n7 .MuiTypography-root{color:#2E0F4F !important;font-weight:700 !important;}
.priceDiferenceColourCell_p1x2lgzj .MuiTypography-root{color:#FF3D3D !important;font-weight:700 !important;}
.secondaryColourCell_s16j4f4x .MuiTypography-root{color:#17AB33 !important;font-weight:700 !important;}
.mobileArrowForward_m5r2pqa{right:5px;}@media (max-width:959.95px){.mobileArrowForward_m5r2pqa{right:-25px;}}
.mobileArrowBack_m1bjxfsi{left:5px;}@media (max-width:959.95px){.mobileArrowBack_m1bjxfsi{left:-25px;}}.mobileArrowBack_m1bjxfsi svg{position:relative;left:6px;}
.mobileArrow_m1f5936p{position:absolute !important;top:-7px;z-index:1000;cursor:pointer;color:#2E0F4F;background:none;border:none;width:32px;height:32px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.mobileArrow_m1f5936p .MuiSvgIcon-root{width:16px;height:16px;background-color:white;border-radius:50%;}
.compare_c1ilr5ur{gap:8px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;padding:8px 16px;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-bottom:8px;max-width:-webkit-fit-content;max-width:-moz-fit-content;max-width:fit-content;}@media (max-width:959.95px){.compare_c1ilr5ur{padding:5vw;}}@media (max-width:599.95px){.compare_c1ilr5ur{-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;min-width:90%;max-width:90%;}}.compare_c1ilr5ur .MuiSelect-select:focus{background-color:inherit !important;}
.formControl_fcfg8fh{min-width:125px;width:100%;}@media (min-width:960px){.formControl_fcfg8fh{width:auto;}}.formControl_fcfg8fh .MuiOutlinedInput-root{border-radius:24px;min-width:226px;}@media (max-width:959.95px){.formControl_fcfg8fh .MuiOutlinedInput-root{min-width:100%;}}
