.hidenProducts_hnh883w{display:none;}
.buttonContainerMobile_b1p3fo57{padding-bottom:24px;}
.buttonContainerDesktop_bnxh9ty{padding-top:24px;}
.backButton_bz1r95j{min-width:212px !important;}
.buttonBox_b1gn1wqg{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:100%;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}@media (max-width:959.95px){.buttonBox_b1gn1wqg{-webkit-flex-direction:column-reverse;-ms-flex-direction:column-reverse;flex-direction:column-reverse;gap:8px;}}
.container_c12zfa8v{color:#2E0F4F;}.container_c12zfa8v .MuiSvgIcon-colorSecondary{cursor:pointer;}.container_c12zfa8v #payment-error{margin-top:40px;}
.detailContainer_d13mwxzh.MuiGrid-root{background-color:#F8F8F8;padding:24px;border-radius:20px;margin:8px 0;}.detailContainer_d13mwxzh.MuiGrid-root .MuiTypography-body2{font-weight:400;}@media (min-width:960px){.detailContainer_d13mwxzh.MuiGrid-root{-webkit-flex-basis:49%;-ms-flex-preferred-size:49%;flex-basis:49%;margin:16px 0;}}
.infoBanner_i1w0uh22{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;color:white;background-color:#848FA5;border-radius:20px;padding:16px;box-shadow:0px 3px 6px #00000054;}.infoBanner_i1w0uh22 .MuiSvgIcon-root{height:30px;width:30px;margin-right:8px;}
.infoBannerPrimary_i1ipi0bx{background-color:#2E0F4F;}
.productsContainer_pxkng7u.MuiGrid-container{margin:8px 0;padding:24px;background-color:#F8F8F8;border-radius:20px;}.productsContainer_pxkng7u .MuiGrid-grid-md-6:nth-child(2){border-bottom:1px solid #EBEBEB;}.productsContainer_pxkng7u .MuiGrid-grid-md-6:nth-child(2) .MuiBox-root > div{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;padding-top:8px;}.productsContainer_pxkng7u .MuiGrid-grid-md-6:nth-child(2) .MuiBox-root > div .MuiSvgIcon-root{margin-right:5px;position:relative;top:5px;}@media (min-width:960px){.productsContainer_pxkng7u .MuiGrid-grid-md-6:nth-child(2){display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-webkit-justify-content:flex-end;-ms-flex-pack:end;justify-content:flex-end;text-align:right;}.productsContainer_pxkng7u .MuiGrid-grid-md-6:nth-child(2) .MuiBox-root > div{-webkit-flex-direction:row-reverse;-ms-flex-direction:row-reverse;flex-direction:row-reverse;}.productsContainer_pxkng7u .MuiGrid-grid-md-6:nth-child(2) .MuiBox-root > div .MuiSvgIcon-root{margin-left:8px;}}@media (min-width:1280px){.productsContainer_pxkng7u .MuiGrid-grid-md-6:nth-child(2) .MuiBox-root > div{-webkit-align-items:baseline;-webkit-box-align:baseline;-ms-flex-align:baseline;align-items:baseline;}.productsContainer_pxkng7u .MuiGrid-grid-md-6:nth-child(2) .MuiSvgIcon-root{top:2px;}}.productsContainer_pxkng7u .MuiTypography-body2{font-weight:400;}.productsContainer_pxkng7u .MuiTypography-colorSecondary{color:#17AB33;}.productsContainer_pxkng7u .MuiTypography-colorSecondary span:nth-child(2){font-size:1.125rem;}@media (min-width:960px){.productsContainer_pxkng7u.MuiGrid-container{margin:16px 0;}.productsContainer_pxkng7u .MuiGrid-grid-md-6:first-child{border-bottom:1px solid #EBEBEB;border-radius:0% !important;}.productsContainer_pxkng7u .MuiGrid-grid-md-6:last-child{text-align:right;padding-top:24px !important;}}
.otsContainer_o10pcvgd{color:#524658;}.otsContainer_o10pcvgd h4{color:#2E0F4F;}.otsContainer_o10pcvgd.MuiGrid-container{margin:8px 0;padding:24px;background-color:#F8F8F8;border-radius:20px;}.otsContainer_o10pcvgd .MuiTypography-body1{font-weight:400;}.otsContainer_o10pcvgd .MuiTypography-body2{font-weight:400;}.otsContainer_o10pcvgd a{margin-left:4px;font-weight:700;color:#17AB33;white-space:nowrap;}.otsContainer_o10pcvgd a:hover{cursor:pointer;}.otsContainer_o10pcvgd strong,.otsContainer_o10pcvgd b{color:#524658;font-weight:600;}.otsContainer_o10pcvgd .MuiTooltip-popper{border-radius:8px;box-shadow:0px 3px 40px 0px rgba(0,0,0,0.16);top:-4px !important;width:284.96px;}@media (max-width:959.95px){.otsContainer_o10pcvgd .MuiTooltip-popper{top:-76px !important;left:50% !important;-webkit-transform:translateX(-50%) !important;-ms-transform:translateX(-50%) !important;transform:translateX(-50%) !important;}}.otsContainer_o10pcvgd .MuiTooltip-tooltip{background-color:white;margin:0;padding:24px;line-height:20.96px;font-size:1rem;color:#524658;font-weight:600;}.otsContainer_o10pcvgd .MuiTooltip-popperArrow[x-placement*="top"] .MuiTooltip-arrow::before{border-color:white transparent transparent transparent;}@media (max-width:959.95px){.otsContainer_o10pcvgd .MuiTooltip-popperArrow[x-placement*="top"] .MuiTooltip-arrow::before{display:none;}}.otsContainer_o10pcvgd #accountId-label{padding-right:24px;}.otsContainer_o10pcvgd .MuiCircularProgress-root{width:86.96px;height:86.96px;}
.toolTipWrapper_twm2j1e{position:relative;z-index:2;}.toolTipWrapper_twm2j1e a{cursor:pointer;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;position:absolute;right:0;}@media (max-width:459.95px){.toolTipWrapper_twm2j1e a{top:8px;}}
.toolTipModal_tqt7vu1 b{font-weight:600 !important;}@media (max-width:599.95px){.toolTipModal_tqt7vu1 button{width:100%;}}
.progressScreen_p6vpyhy .MuiCircularProgress-root{display:block;margin:0 auto;width:86.96px;height:86.96px;}
.mainIcon_mpodpsn{width:50px !important;height:50px !important;}
.loading_lfewe1s{margin-left:24px;vertical-align:middle;}
.errorLabel_ewbhlns .MuiInput-root.MuiInput-underline:before,.errorLabel_ewbhlns .MuiInput-root.MuiInput-underline:after,.errorLabel_ewbhlns .MuiInput-underline:hover:not(.Mui-disabled):before{border-bottom:1px solid #FF3D3D;}
.checkbox_c1gl7wde{position:relative;}.checkbox_c1gl7wde .MuiFormControlLabel-root{position:relative;margin-top:16px;}.checkbox_c1gl7wde .MuiCheckbox-root{position:absolute;top:-10.96px;}.checkbox_c1gl7wde .MuiFormControlLabel-label{color:#524658;padding-left:40px;font-size:0.875rem;}
.errorCheckbox_e7p3ymh span{color:#FF3D3D;}
.otsConfirmationError_oszbobq{padding:24px;border-radius:20px;border:1px solid #FF3D3D;}.otsConfirmationError_oszbobq svg{display:block;width:36px;height:36px;margin-bottom:8px;}
.monthlyCost_misdrur{color:#17AB33;}
.discount_d1k2nopb{color:#17AB33;}.discount_d1k2nopb.MuiTypography-root{font-size:15px;font-weight:normal;}
.vulnerableMention_vn13siz{padding-top:32px;}.vulnerableMention_vn13siz .MuiTypography-root{font-size:0.875rempx;font-weight:normal;}
.wier_w10lnbsn{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:flex-end;-webkit-box-align:flex-end;-ms-flex-align:flex-end;align-items:flex-end;}.wier_w10lnbsn svg{font-size:1.8rem;margin-right:2px !important;}@media (max-width:959.95px){.wier_w10lnbsn svg{left:-2px;}}
.summaryPrice_s819tfj{color:#524658;padding-top:16px;}
