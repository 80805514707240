.button_b16vgqsq{text-align:center;}.button_b16vgqsq:not(.disablePadding).MuiButton-root{padding-left:1.5em;padding-right:1.5em;}.button_b16vgqsq.disablePadding{line-height:1.5;padding:0;}.button_b16vgqsq.disablePadding .MuiButton-label{margin:0;font-weight:600;}
.packageButtons_p1g07im7{width:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:8px;margin-bottom:24px;margin-top:24px;}@media (max-width:959.95px){.packageButtons_p1g07im7{-webkit-flex-direction:column-reverse;-ms-flex-direction:column-reverse;flex-direction:column-reverse;}}@media (min-width:600px){.packageButtons_p1g07im7{-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;}}
.justifyEnd_jkg4ohs{-webkit-box-pack:end;-webkit-justify-content:flex-end;-ms-flex-pack:end;justify-content:flex-end;}
.justifyBetween_j25aycj{-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}
.hideBack_hrw1mmf{display:none !important;}
.fullWidth_f1niyrqj{width:100%;}
.blueGradient_b1d9q6rp.MuiButton-root{background:linear-gradient(270deg,#380A60 0%,#295D9A 100%);}.blueGradient_b1d9q6rp.MuiButton-root:hover{background:linear-gradient(270deg,#20023b 0%,#084185 100%);}
.moreInfoBtn_mwvwy9{max-width:80%;}.moreInfoBtn_mwvwy9.MuiButtonBase-root{margin-top:8px;}@media (max-width:959.95px){.moreInfoBtn_mwvwy9.MuiButtonBase-root{padding:8px 0px 40px;}}
.disableContained_dnmmrig.MuiButton-contained{color:#fff !important;opacity:0.2 !important;background:#17AB33 !important;}
.activeContained_a1w3i9rj.MuiButton-contained:hover{background-color:unset !important;color:unset !important;box-shadow:unset !important;}
.direction_d1llnep8.MuiButtonBase-root{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
